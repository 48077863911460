.OrderTooltipOrderBubble {
    .UiOverlay {
        z-index: calc(var(--z-index-OrdersPlaced) - 1);
    }

    .content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        position: fixed;
        height: 200px;
        width: 100%;
        right: 0;
        top: 0;
        z-index: 1;
        background-color: var(--color-success);
        border-bottom-left-radius: 75%;
        padding-top: 16px;
        padding-right: calc(80px + 32px);
        text-align: right;

        button.UiButton {
            background-color: white !important;
            color: black;
        }
    }

    .bubble {
        background-color: white;
        border-radius: 100%;
        position: absolute;
        top: var(--spacing);
        right: var(--spacing);
        height: 80px;
        width: 80px;
    }
}
