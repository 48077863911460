$icon-size: 32px;

.ProductListItem {
    background-color: var(--color-background-paper);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: var(--spacing);
    position: relative;

    .meta {
        padding-right: 40px;
    }

    .product-icon {
        background-size: cover;
        height: $icon-size;
        width: $icon-size;
        min-width: $icon-size;
    }

    .options-container {
        position: absolute;
        right: 4px;
        top: 4px;
    }

    .price {
        font-size: 14px;
        color: var(--color-text-secondary);
        font-weight: 700;
    }
}
