.NavTab {
    border-bottom: 1px solid var(--color-divider);

    .UiButton {
        border-radius: 0;
    }

    .UiButton.active {
        border-bottom: 2px solid var(--color-secondary);
        color: var(--color-secondary);
    }
}
