.ZonePage {
    .image {
        z-index: -1;

        &,
        .image-overlay {
            position: absolute;
            height: 200px;
            width: 100%;
        }

        .image-overlay {
            background-image: linear-gradient(rgba(0, 0, 0, 0.8), var(--color-background-body));
        }
    }
}
