.DeliveryStationOrderItem {
    background-color: var(--color-background-paper);
    padding: var(--spacing);
    position: relative;
    border-bottom: 1px solid var(--color-divider);

    &:first-child {
        border-top: 1px solid var(--color-divider);
    }

    .order-in-delivery {
        background-image: linear-gradient(to right, transparent, var(--color-success), transparent);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: inPreparation 2s infinite;
    }

    .overlay-dark {
        animation-duration: 300ms !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.95);
        padding: var(--spacing);
        overflow: auto;
    }

    .product-status-overlay {
        button {
            border-radius: 0;

            &:first-child {
                border-top-left-radius: var(--border-radius);
                border-bottom-left-radius: var(--border-radius);
            }

            &:last-child {
                border-top-right-radius: var(--border-radius);
                border-bottom-right-radius: var(--border-radius);
            }

            &:not(:first-child) {
                border-left: 2px solid var(--color-background-body);
            }
        }
    }

    .product-item {
        position: relative;
        overflow: hidden;
        border-radius: var(--border-radius);

        button {
            border-radius: 0;
            padding: 0 8px;
            text-transform: initial;
        }

        .button-comment {
            background-color: var(--color-secondary);
            position: relative;
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
            height: 24px;
            width: 24px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }

        &.DELIVERED {
            button {
                background-color: var(--color-success);
                text-decoration: line-through;
            }
        }
    }

    > .content {
        &,
        > :first-child {
            max-width: 100%;
            overflow: auto;
        }
    }

    &.status-PLACED {
        .sequence-label-container {
            background-color: var(--color-yellow);
            color: black;

            label {
                background-color: var(--color-yellow);
            }
        }
    }

    &.status-mixed,
    &.status-IN_PREPARATION {
        .sequence-label-container {
            &::before {
                background-image: linear-gradient(transparent 50%, var(--color-yellow) 50%);
            }

            label {
                background-color: var(--color-background-body);
            }
        }
    }

    &.status-READY {
        .sequence-label-container {
            background-color: var(--color-success);
            color: white;
        }
    }

    &.status-DELIVERED {
        background-color: var(--color-success);

        .sequence-label-container,
        .sequence-label-container label {
            background-color: var(--color-success);
        }

        .products-container button {
            background-color: var(--color-success);
        }
    }

    .sequence-label-container {
        background-color: var(--color-background-body);
        width: 32px;
        height: 32px;
        padding: 2px;
        position: relative;
        overflow: hidden;
        animation-duration: 2s !important;
        transform: translateZ(0);

        &::before {
            content: '';
            position: absolute;
            left: -50%;
            top: -50%;
            width: 200%;
            height: 200%;
            animation: rotate 1s infinite;
        }

        &,
        label {
            border-radius: var(--border-radius);
        }

        label {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            height: 100%;
            width: 100%;
            position: relative;
            z-index: 1;
        }
    }

    .badge {
        background-color: black;
        color: var(--color-yellow);
        padding: 0 2px;
        border-radius: var(--border-radius);
    }

    .products-container {
        display: inline-flex;
        flex-wrap: wrap;

        > * {
            margin-right: 2px;
            margin-bottom: 2px;
        }
    }

    .product-status-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 3px;
        width: 100%;
        overflow: hidden;

        .bar-container {
            overflow: hidden;
        }

        .bar-container,
        .bar-container > * {
            height: 100%;
        }

        .PLACED {
            background-color: var(--color-yellow);
        }

        .IN_PREPARATION {
            background-image: linear-gradient(to right, transparent, #666);
            animation: inPreparation 1s infinite;
        }

        .READY,
        .DELIVERED {
            background-color: var(--color-success);
        }
    }

    button {
        font-weight: bold;
        overflow: hidden;
    }

    .product-status-overlay button,
    .product-row button {
        white-space: initial;
        line-height: 0.8;
    }
}

@keyframes inPreparation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}
