.UiButtonX {
    --button-before-hover-background-color: rgba(255, 255, 255, 0.1);
    --button-before-hover-text-background-color: rgba(0, 0, 0, 0.05);

    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    transition: box-shadow 200ms;
    // Disable double tap zoom on iOS Safari
    touch-action: manipulation;

    &[disabled] {
        opacity: 0.5;
    }

    &:focus {
        outline: none;
    }

    &:before {
        background-color: var(--button-before-hover-background-color);
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: var(--border-radius);
        opacity: 0;
        transition: opacity 200ms;
    }

    &:focus:before,
    &:hover:before {
        opacity: 1;
    }

    &.direction-column {
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &.fullWidth {
        width: 100%;
    }

    &.noWrap {
        white-space: nowrap;
    }

    &.size-small {
        min-height: 30px;
        font-size: 12px;
    }

    &.size-default {
        min-height: 34px;
    }

    &.size-large {
        min-height: 38px;
    }

    &.variant-outlined,
    &.variant-text {
        &.color-default {
            color: var(--color-text-primary);
            fill: var(--color-text-primary);
        }

        &.color-primary {
            color: var(--color-primary);
            fill: var(--color-primary);
        }

        &.color-secondary {
            color: var(--color-secondary);
            fill: var(--color-secondary);
        }

        &.color-success {
            color: var(--color-success);
            fill: var(--color-success);
        }

        &.color-error {
            color: var(--color-error);
            fill: var(--color-error);
        }
    }

    &.variant-outlined {
        &.color-default {
            box-shadow: 0 0 0 1px var(--color-divider);
        }

        &.color-primary {
            box-shadow: 0 0 0 1px var(--color-primary);
        }

        &.color-secondary {
            box-shadow: 0 0 0 1px var(--color-secondary);
        }

        &.color-success {
            box-shadow: 0 0 0 1px var(--color-success);
        }

        &.color-error {
            box-shadow: 0 0 0 1px var(--color-error);
        }
    }

    &.variant-filled {
        box-shadow: 0 0 0 1px var(--color-divider);

        &.color-default {
            background-color: var(--color-background-paper);
            color: var(--color-text-primary);
            fill: var(--color-text-primary);
        }

        &.color-primary {
            background-color: var(--color-primary);
            color: white;
            fill: white;
        }

        &.color-secondary {
            background-color: var(--color-secondary);
            color: white;
            fill: white;
        }

        &.color-success {
            background-color: var(--color-success);
            color: white;
            fill: white;
        }

        &.color-error {
            background-color: var(--color-error);
            color: white;
            fill: white;
        }
    }
}
