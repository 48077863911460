$padding-vertical: calc(1.5 * var(--spacing));

.ProductCategoryTabs {
    overflow: hidden;
    position: relative;

    .badge {
        position: absolute;
        top: -12px;
        right: -12px;
        background-color: var(--color-secondary);
        font-weight: bold;
        font-size: 12px;
        color: white;
        padding: 0 8px;
        border-radius: 40px;
    }

    > * {
        position: relative;
        padding-bottom: 2px;

        &::before,
        .UiButton::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--color-divider);
        }

        .UiButton {
            color: var(--color-text-secondary);
            border: 0;
            border-radius: 0;
            padding-top: $padding-vertical;
            padding-bottom: $padding-vertical;

            &::after {
                bottom: -2px;
            }

            &.color-secondary::after {
                background-color: var(--color-secondary);
            }
        }
    }
}
