.PosGroupSplitBill {
    .divider {
        text-transform: uppercase;
        text-align: center;
        font-size: 12px;
        letter-spacing: 1px;
        position: relative;

        &:before {
            content: '';
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-image: linear-gradient(
                to right,
                var(--color-divider),
                transparent 25%,
                transparent 75%,
                var(--color-divider)
            );
            height: 2px;
            width: 100%;
            position: absolute;
        }
    }
}
