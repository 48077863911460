.OrderHistoryPage {
    .image-container {
        height: 40px;
        width: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 100%;
        position: relative;
    }

    .item-row {
        padding: var(--spacing) calc(2 * var(--spacing));
        border-bottom: 1px solid var(--color-divider);
    }
}
