.UiTableCellCheckbox {
    --checkbox-size: calc(24px + 8px);

    padding: 0 !important;
    width: var(--checkbox-size);
    height: var(--checkbox-size);

    .MuiCheckbox-root {
        width: var(--checkbox-size);
        height: var(--checkbox-size);
    }
}
