.DeliveryStationFilter {
    .filter-container {
        position: relative;
        padding: var(--spacing);
        border-bottom: 1px solid var(--color-divider);

        label {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--color-background-paper);
            border-radius: var(--border-radius);
            z-index: 1;
            padding: 2px 8px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 1px;
        }
    }
}
