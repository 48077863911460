.LocationBrowseListCard {
    height: 128px;
    overflow: hidden;
    border-radius: calc(var(--border-radius) * 1.5);
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    .closed-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(24, 24, 24, 0.9);
        height: 100%;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: calc(2 * var(--spacing));
        font-weight: bold;
    }

    > .content {
        background-color: var(--color-background-transparent);
        position: relative;
        padding: calc(2 * var(--spacing));
        padding-right: calc(4 * var(--spacing));
        height: 100%;
    }

    .button-container {
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(2 * var(--UiSize-1));
        height: calc(2 * var(--UiSize-1));
        background-image: linear-gradient(135deg, transparent 50%, var(--color-background-paper) 50%);

        button {
            box-shadow: none;
            transform: rotate(180deg);
            //color: var(--color-secondary);
        }
    }

    .description {
        margin-top: 4px;
        color: var(--color-text-secondary);
        font-weight: bold;
    }

    button {
        position: absolute;
        right: 0;
        bottom: 0;
        box-shadow: var(--box-shadow);
    }
}
