.UiToggle {
    background-color: var(--color-background-body);
    padding: 4px;
    position: relative;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-divider);

    > .content {
        border-radius: var(--border-radius);
        position: relative;
    }

    button {
        height: 100%;

        &:not(.indicator) {
            border-radius: 0;

            &:before {
                display: none;
            }
        }
    }

    .indicator {
        background-color: var(--color-secondary);
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 200ms;

        @for $i from 1 through 8 {
            &.buttons-#{$i} {
                width: calc(100% / #{$i});
            }
        }

        @for $i from 0 through 7 {
            &.active-#{$i} {
                transform: translateX(100% * $i);
            }
        }
    }
}
