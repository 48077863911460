@font-face {
    font-family: 'icon-pack-cocktail';
    src: url('/assets/icon-pack-cocktail.woff') format('woff');
}

[class^='icon-pack-cocktail-']:before,
[class*=' icon-pack-cocktail-']:before,
[class^='icon-pack-cocktail-']:after,
[class*=' icon-pack-cocktail-']:after {
    font-family: 'icon-pack-cocktail';
}

.icon-pack-cocktail-1:before {
    content: '\f100';
}
.icon-pack-cocktail-2:before {
    content: '\f101';
}
.icon-pack-cocktail-3:before {
    content: '\f102';
}
.icon-pack-cocktail-4:before {
    content: '\f103';
}
.icon-pack-cocktail-5:before {
    content: '\f104';
}
.icon-pack-cocktail-6:before {
    content: '\f105';
}
.icon-pack-cocktail-7:before {
    content: '\f106';
}
.icon-pack-cocktail-8:before {
    content: '\f107';
}
.icon-pack-cocktail-9:before {
    content: '\f108';
}
.icon-pack-cocktail-10:before {
    content: '\f109';
}
.icon-pack-cocktail-11:before {
    content: '\f10a';
}
.icon-pack-cocktail-12:before {
    content: '\f10b';
}
.icon-pack-cocktail-13:before {
    content: '\f10c';
}
.icon-pack-cocktail-14:before {
    content: '\f10d';
}
.icon-pack-cocktail-15:before {
    content: '\f10e';
}
.icon-pack-cocktail-16:before {
    content: '\f10f';
}
.icon-pack-cocktail-17:before {
    content: '\f110';
}
.icon-pack-cocktail-18:before {
    content: '\f111';
}
.icon-pack-cocktail-19:before {
    content: '\f112';
}
.icon-pack-cocktail-20:before {
    content: '\f113';
}
.icon-pack-cocktail-21:before {
    content: '\f114';
}
.icon-pack-cocktail-22:before {
    content: '\f115';
}
.icon-pack-cocktail-23:before {
    content: '\f116';
}
.icon-pack-cocktail-24:before {
    content: '\f117';
}
.icon-pack-cocktail-25:before {
    content: '\f118';
}
.icon-pack-cocktail-26:before {
    content: '\f119';
}
.icon-pack-cocktail-27:before {
    content: '\f11a';
}
.icon-pack-cocktail-28:before {
    content: '\f11b';
}
.icon-pack-cocktail-29:before {
    content: '\f11c';
}
.icon-pack-cocktail-30:before {
    content: '\f11d';
}
.icon-pack-cocktail-31:before {
    content: '\f11e';
}
.icon-pack-cocktail-32:before {
    content: '\f11f';
}
.icon-pack-cocktail-33:before {
    content: '\f120';
}
.icon-pack-cocktail-34:before {
    content: '\f121';
}
.icon-pack-cocktail-35:before {
    content: '\f122';
}
.icon-pack-cocktail-36:before {
    content: '\f123';
}
.icon-pack-cocktail-37:before {
    content: '\f124';
}
.icon-pack-cocktail-38:before {
    content: '\f125';
}
.icon-pack-cocktail-39:before {
    content: '\f126';
}
.icon-pack-cocktail-40:before {
    content: '\f127';
}
