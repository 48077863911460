.StaffOrderStationListItem {
    background: var(--color-background-paper);
    border-radius: var(--border-radius);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: 48px;
    padding-right: 8px;

    .sequence {
        width: 32px;
        font-weight: 700;
        height: 100%;
        position: relative;

        &.PLACED {
            background-color: var(--color-yellow);
            color: var(--color-text-primary-inverse);
        }

        &.READY {
            background: var(--color-success);
        }

        &.IN_PREPARATION,
        &.IN_DELIVERY {
            background: var(--color-success);
            color: var(--color-text-primary-inverse);
        }
    }

    .customer-placed {
        background-color: var(--color-background-body);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: var(--color-yellow);
        border-radius: var(--border-radius);
        padding: 2px 8px;
        font-weight: 700;
    }
}
