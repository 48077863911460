.IncrementDecrement {
    --button-increment-decrement-size: 40px;

    position: relative;

    .button {
        width: var(--button-increment-decrement-size);
        min-width: var(--button-increment-decrement-size);
        height: var(--button-increment-decrement-size);
        box-shadow: none;
        opacity: 0.9;
        font-size: 1rem;

        &[disabled] {
            opacity: 0.25;
        }
    }

    .button-group {
        & > :nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & > :nth-child(2) {
            border-radius: 0;
        }

        & > :nth-child(3) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .button-decrement {
        position: absolute;
        right: calc(var(--button-increment-decrement-size) * 2);
    }

    .button-label {
        position: absolute;
        right: var(--button-increment-decrement-size);
    }

    .upper-row {
        position: absolute;
        display: flex;
        right: 0;
        bottom: var(--button-increment-decrement-size);
    }
}
