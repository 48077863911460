.V2OrderPlaced {
    position: fixed;
    top: var(--spacing);
    right: var(--spacing);
    z-index: var(--z-index-OrdersPlaced);

    .toggle-compact {
        background-color: var(--color-background-body);
        height: 80px;
        width: 80px;
        border-radius: 100%;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        i {
            font-size: 2rem;
        }
    }

    .item {
        background-color: var(--color-background-paper);
        box-shadow: var(--box-shadow);
        height: 80px;
        width: 80px;
        border-radius: 100%;
        overflow: hidden;
        transition: transform 300ms;
        transform: translateY(0px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 4px;

        .content {
            background-color: var(--color-background-body);
            height: 100%;
            width: 100%;
            border-radius: 100%;
            z-index: 1;
            position: relative;

            .overlay {
                height: 100%;
                width: 100%;
                background-color: var(--color-overlay-dark);
                position: absolute;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .indicator {
            animation: spin 3s infinite;
            animation-timing-function: linear;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-left: 32px solid;
        }

        &.DELIVERED {
            .indicator {
                border-left-color: var(--color-success);
            }
        }

        &.PLACED {
            .indicator {
                border-left-color: var(--color-yellow);
            }
        }

        &.IN_PREPARATION {
            .indicator {
                border-left-color: var(--color-yellow);
            }
        }

        &.READY {
            .indicator {
                border-left-color: var(--color-success);
            }
        }

        @for $i from 1 through 10 {
            &.compact:nth-child(#{$i}) {
                transform: translateY(#{($i - 1) * 80px * -0.95});
                z-index: #{10 - $i};
            }
        }

        .pulse {
            animation-duration: 3s;
        }
    }

    //.line {
    //    height: 80px;
    //    width: 80px;
    //    border-radius: 100%;
    //    position: absolute;
    //    top: 0;
    //    left: 0;
    //    border: 2px solid transparent;
    //    animation: spin 5s infinite;
    //    animation-timing-function: linear;
    //
    //    &.IN_PREPARATION {
    //        border-color: var(--color-yellow);
    //    }
    //
    //    &.READY {
    //        border-color: var(--color-success);
    //    }
    //
    //    .pulse {
    //        animation-duration: 3s;
    //    }
    //}
}
