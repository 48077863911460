.PosCreateHeaderSummary {
    background-color: var(--color-background-paper);
    width: 100%;
    height: 100%;
    padding-left: var(--spacing);

    &.primary {
        background-color: var(--color-primary);
    }
}
