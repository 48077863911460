.ButtonGroup {
    width: 100%;

    button {
        border-radius: 0;
        white-space: initial;
        line-height: 0.8;
        padding: 2px !important;

        &:first-child {
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
        }

        &:last-child {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
        }

        &:not(:first-child) {
            border-left: 2px solid var(--color-background-paper);
        }
    }
}
