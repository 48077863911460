.beer {
    background-image: url('./assets/product-icons/beer.png');
}
.bottle {
    background-image: url('./assets/product-icons/bottle.png');
}
.breakfast {
    background-image: url('./assets/product-icons/breakfast.png');
}
.burger-1 {
    background-image: url('./assets/product-icons/burger-1.png');
}
.burger-2 {
    background-image: url('./assets/product-icons/burger-2.png');
}
.burger {
    background-image: url('./assets/product-icons/burger.png');
}
.cheese {
    background-image: url('./assets/product-icons/cheese.png');
}
.chef-hat {
    background-image: url('./assets/product-icons/chef-hat.png');
}
.chicken-leg-1 {
    background-image: url('./assets/product-icons/chicken-leg-1.png');
}
.chicken-leg {
    background-image: url('./assets/product-icons/chicken-leg.png');
}
.chinese-food {
    background-image: url('./assets/product-icons/chinese-food.png');
}
.chips {
    background-image: url('./assets/product-icons/chips.png');
}
.cocktail {
    background-image: url('./assets/product-icons/cocktail.png');
}
.coffee-cup {
    background-image: url('./assets/product-icons/coffee-cup.png');
}
.coffee-machine {
    background-image: url('./assets/product-icons/coffee-machine.png');
}
.coffee {
    background-image: url('./assets/product-icons/coffee.png');
}
.cognac {
    background-image: url('./assets/product-icons/cognac.png');
}
.cola-1 {
    background-image: url('./assets/product-icons/cola-1.png');
}
.cola {
    background-image: url('./assets/product-icons/cola.png');
}
.croissant {
    background-image: url('./assets/product-icons/croissant.png');
}
.donut {
    background-image: url('./assets/product-icons/donut.png');
}
.french-fries {
    background-image: url('./assets/product-icons/french-fries.png');
}
.fried-chicken {
    background-image: url('./assets/product-icons/fried-chicken.png');
}
.fried-egg {
    background-image: url('./assets/product-icons/fried-egg.png');
}
.fried-potatoes {
    background-image: url('./assets/product-icons/fried-potatoes.png');
}
.gin-tonic {
    background-image: url('./assets/product-icons/gin-tonic.png');
}
.hanger {
    background-image: url('./assets/product-icons/hanger.png');
}
.hot-dog-1 {
    background-image: url('./assets/product-icons/hot-dog-1.png');
}
.ice-cream-1 {
    background-image: url('./assets/product-icons/ice-cream-1.png');
}
.ice-cream {
    background-image: url('./assets/product-icons/ice-cream.png');
}
.kebab {
    background-image: url('./assets/product-icons/kebab.png');
}
.long-drink {
    background-image: url('./assets/product-icons/long-drink.png');
}
.main {
    background-image: url('./assets/product-icons/main.png');
}
.martini {
    background-image: url('./assets/product-icons/martini.png');
}
.meat {
    background-image: url('./assets/product-icons/meat.png');
}
.milkshake {
    background-image: url('./assets/product-icons/milkshake.png');
}
.noodles {
    background-image: url('./assets/product-icons/noodles.png');
}
.package {
    background-image: url('./assets/product-icons/package.png');
}
.pint {
    background-image: url('./assets/product-icons/pint.png');
}
.pitcher {
    background-image: url('./assets/product-icons/pitcher.png');
}
.pizza-slice {
    background-image: url('./assets/product-icons/pizza-slice.png');
}
.popcorn {
    background-image: url('./assets/product-icons/popcorn.png');
}
.red-wine-bottle {
    background-image: url('./assets/product-icons/red-wine-bottle.png');
}
.red-wine {
    background-image: url('./assets/product-icons/red-wine.png');
}
.rice {
    background-image: url('./assets/product-icons/rice.png');
}
.rose {
    background-image: url('./assets/product-icons/rose.png');
}
.salad {
    background-image: url('./assets/product-icons/salad.png');
}
.sauces {
    background-image: url('./assets/product-icons/sauces.png');
}
.sausage {
    background-image: url('./assets/product-icons/sausage.png');
}
.schnapps {
    background-image: url('./assets/product-icons/schnapps.png');
}
.serving-dish {
    background-image: url('./assets/product-icons/serving-dish.png');
}
.shot {
    background-image: url('./assets/product-icons/shot.png');
}
.soda {
    background-image: url('./assets/product-icons/soda.png');
}
.soup {
    background-image: url('./assets/product-icons/soup.png');
}
.sparkling {
    background-image: url('./assets/product-icons/sparkling.png');
}
.taco {
    background-image: url('./assets/product-icons/taco.png');
}
.tea {
    background-image: url('./assets/product-icons/tea.png');
}
.tequila {
    background-image: url('./assets/product-icons/tequila.png');
}
.ticket {
    background-image: url('./assets/product-icons/ticket.png');
}
.tomato-sauce {
    background-image: url('./assets/product-icons/tomato-sauce.png');
}
.waffle {
    background-image: url('./assets/product-icons/waffle.png');
}
.whiskey-on-rocks {
    background-image: url('./assets/product-icons/whiskey-on-rocks.png');
}
.white-wine-bottle {
    background-image: url('./assets/product-icons/white-wine-bottle.png');
}
.white-wine {
    background-image: url('./assets/product-icons/white-wine.png');
}
