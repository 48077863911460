.DeliveryStationHeader {
    .header-visibility-button {
        position: fixed;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 20;

        button {
            opacity: 0.9;
            background-color: var(--color-background-body);
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }
}
