.BetaFeature {
    display: grid;
    box-shadow: 0 0 0 2px blue;
    position: relative;

    &:before {
        background-color: blue;
        content: 'BETA FEATURE';
        position: absolute;
        top: -2px;
        left: -2px;
        z-index: 1;
        font-size: 8px;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 2px 4px;
        border-bottom-right-radius: 2px;
    }
}
