.OrderIdPage {
    height: 100%;
    line-height: normal;

    .sequence {
        //background-color: var(--color-secondary);
        //padding: 4px 16px;
        color: var(--color-secondary);
        display: inline-flex;
        border-radius: 6px;
        font-size: 2rem;
    }

    .video-container {
        position: absolute;
        top: 16px;
        right: 0;
        width: 50%;
        opacity: 0.25;
        overflow: hidden;

        .video {
            position: relative;
            right: -16px;
        }
    }

    .segment {
        background-color: var(--color-background-paper);
        height: calc(1 * var(--spacing));
        width: 100%;
        transition: background-color 200ms;
        position: relative;
        overflow: hidden;

        &.done {
            background-color: var(--color-success);
        }

        &.pending::before {
            opacity: 1;
        }

        &.pending div {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            animation: orderSegmentPending 1s infinite;
            background-image: linear-gradient(to right, transparent, var(--color-success), transparent);
        }
    }

    .UiInput {
        height: 40px !important;
    }

    .buttons {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: calc(2 * var(--spacing));

        button {
            text-transform: uppercase;

            &:first-child {
                border-radius: 40px;
            }
        }
    }

    .line-through {
        text-decoration: underline;
    }

    b {
        color: var(--color-secondary);
        text-decoration: underline;
    }

    .alert {
        background-color: var(--color-warning);
        border-radius: var(--border-radius);
        color: var(--color-black);
        padding: 16px;

        .label {
            color: var(--color-white);
        }

        input::placeholder {
            color: var(--color-black);
        }
    }

    .order-details {
        background-color: var(--color-background-body);
        position: fixed;
        bottom: var(--UiSize-1);
        height: var(--UiSize-1);
        width: 100%;
        z-index: 1;
        transition: 200ms;
        overflow: auto;

        &.expanded {
            height: 400px;
        }

        button {
            background-color: black;
            width: 100%;
            position: sticky;
            top: 0;
        }
    }
}

@keyframes orderSegmentPending {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
