@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast-container {
    padding: 16px;
}

.Toastify__toast {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 24px !important;
    font-family: inherit;
    margin-bottom: 8px;
}

.Toastify__progress-bar {
    height: 2px;
}

.Toastify__toast--error {
    background-color: var(--color-error);
}

.Toastify__toast--success {
    background-color: var(--color-success);
}
