.UiTable {
    width: 100%;
    border-spacing: 0;
    font-weight: inherit;
    font-size: 14px;

    &.regularFont {
        font-size: 1rem;
    }

    thead {
        font-weight: bold;
        color: var(--color-text-secondary);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 1;
    }

    tr.divider {
        background-color: var(--color-background-header);
    }

    tbody tr:not(:first-child) {
        box-shadow: 0 -1px 0pt 0px var(--color-divider);
    }

    tfoot {
        font-weight: bold;
    }

    td {
        padding: 8px;
    }

    td .icon-container {
        background-color: var(--color-background-paper-accent);
        border-radius: var(--border-radius);
        height: 32px;
        width: 32px;
        padding: 4px;
    }

    .checkbox {
        width: 48px;
    }
}
