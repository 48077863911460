.ProductCard {
    --ProductCard-image-size: 80px;
    --ProductCard-button-size: 32px;
    --ProductCard-icon-size: 40px;

    background-color: var(--color-background-paper);
    border-radius: var(--border-radius);

    .icon-container {
        .product-icon {
            font-size: var(--ProductCard-icon-size);
            height: var(--ProductCard-icon-size);
            min-height: var(--ProductCard-icon-size);
            width: var(--ProductCard-icon-size);
            min-width: var(--ProductCard-icon-size);
            display: flex;
            align-items: center;
            justify-content: center;
            //color: var(--color-text-secondary);
            color: var(--color-secondary);
            background-size: cover;
            background-position: center;
        }
    }

    .card-content {
        align-items: stretch !important;
        position: relative;
        padding: 8px;

        > *:not(:first-child) {
            margin-left: 12px;
        }

        .description {
            line-height: normal;

            //&:not(.visible) {
            //    hyphens: auto;
            //    display: -webkit-box;
            //    -webkit-line-clamp: 2;
            //    -webkit-box-orient: vertical;
            //    text-overflow: ellipsis;
            //    overflow: hidden;
            //}
        }

        .options-container {
            position: absolute;
            bottom: var(--spacing);
            right: var(--spacing);

            button {
                transition: 200ms;
            }

            .upper-row {
                bottom: var(--ProductCard-button-size);
            }

            .button-decrement {
                background-color: var(--color-background-body);
                right: calc(var(--ProductCard-button-size) * 2);
            }

            .button-label {
                right: calc(var(--ProductCard-button-size) * 1);
                font-size: 18px;
            }

            .button-increment {
                color: var(--color-secondary);
                background-color: var(--color-background-body);
                border-radius: var(--border-radius);
            }
        }

        .image {
            border-radius: var(--border-radius);
            background-color: var(--color-background-body);
            height: var(--ProductCard-image-size);
            min-width: calc(var(--ProductCard-image-size));
            transform: translateZ(0);
            overflow: hidden;

            div {
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
            }
        }
    }

    &.isAsset {
        .image {
            background-color: transparent;
            --ProductCard-image-size: 40px;
        }
    }

    &.hasItems {
        .options-container {
            --ProductCard-button-size: 40px;
        }
    }
}
