.PosGroupFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 50px;
    background-color: var(--color-background-body);
    z-index: var(--z-index-above-toolbar);
    width: 100%;
    padding: var(--spacing);
}
