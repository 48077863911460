.DeliveryStationHeaderSettings {
    .item {
        padding: var(--spacing);
        border-top: 1px solid var(--color-divider);
    }

    .filter-active {
        color: var(--color-success);
    }

    .table-grid {
        overflow: auto;
        max-height: 310px;
    }
}
