.OrderIdViewCompact {
    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: -1;
    }

    .info {
        border-radius: 16px;
    }

    .ready {
        border-radius: 24px;
    }

    .info {
        background: var(--color-secondary);
        color: var(--color-white);
        position: relative;

        .UiIconMaterial {
            font-size: 3rem;
        }
    }

    &.isDelivered {
        .info {
            background-color: var(--color-success);
        }
    }

    .image-container {
        height: 96px;
        width: 96px;
        border-radius: 100%;
        overflow: hidden;
        transform: translateZ(0);
    }

    .close-button-container {
        position: fixed;
        top: var(--spacing);
        right: var(--spacing);

        .UiButton {
            height: 56px !important;
            width: 56px !important;

            i {
                font-size: 2rem;
            }
        }
    }

    .UiTable {
        font-size: 1.25rem;
        overflow: initial;
    }

    .ready-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .ready {
            background-color: var(--color-secondary);
            color: var(--color-white);
            height: 100%;
            width: 100%;
            overflow: auto;

            .image-container {
                background-color: transparent;
            }
        }

        .sequence {
            line-height: normal;

            .number {
                font-size: 40vw;
            }
        }
    }
}
