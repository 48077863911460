.PosCreateProductPriceLabel {
    display: inline-flex;
    align-items: center;
    position: relative;

    .percent {
        position: absolute;
        left: 50%;
        top: -8px;

        transform: translateX(-50%);
        font-weight: bold;
        font-size: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: var(--border-radius);
        padding: 2px 4px;
    }

    .old-price {
        text-decoration: line-through;
        color: var(--color-text-secondary);
    }

    .new-price {
        font-weight: bold;
        color: var(--color-success);
    }
}
