.PosFooterTerminal {
    > .container,
    > .placeholder {
        height: 50px;
    }

    > .container {
        background-color: var(--color-error);
        border-top: 2px solid var(--color-divider);
        overflow: auto;
        position: fixed;
        bottom: 50px;
        width: 100%;
        z-index: 2;
        transition: background-color 200ms, border 200ms;

        &.connected {
            background-color: var(--color-background-body);
            border-top-color: var(--color-success);
        }
    }

    button {
        font-weight: bold;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        margin-left: 4px;
    }
}
