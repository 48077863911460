.PosGroupListItem {
    background-color: var(--color-background-paper);
    padding: 8px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    .name {
        font-weight: bold;
        text-align: center;
    }

    .payment {
        position: relative;
        border-radius: var(--border-radius);
        padding: 2px;
        background-color: var(--color-background-body);
        text-transform: uppercase;
        font-weight: bold;
        color: var(--color-warning);
        text-align: center;

        .divider {
            color: var(--color-text-secondary);
            flex: 0;
            white-space: nowrap;
        }

        &.all-paid {
            color: var(--color-success);
        }
    }

    .button-group-container {
        .ButtonGroup {
            border: 1px solid var(--color-divider);
            border-radius: var(--border-radius);
        }

        button {
            height: var(--UiSize-0);
        }
    }
}
