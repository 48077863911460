@import '~animate.css/animate.css';
@import './styles/react-simple-keyboard.scss';
@import './styles/react-toastify.scss';
@import './styles/slick-carousel.scss';
@import './styles/react-dates';
@import './product-icons.css';
@import '~@orderx/ui/index.css';
@import './styles/icon-pack-cocktail.scss';

html {
    --color-background-transparent: rgba(15, 15, 15, 0.75);
    //--box-shadow: 0 2px 8px #111 !important;

    --color-background-transparent: rgba(24, 24, 24, 0.8);
    --color-background-body: #121212 !important;
    --color-background-paper: #232323 !important;

    --z-index-above-toolbar: calc(100 + var(--UiToolbar-z-index));
    --z-index-OrdersPlaced: calc(101 + var(--UiToolbar-z-index));
    --z-index-Actionsheet: calc(102 + var(--UiToolbar-z-index));
    --z-index-Overlay: calc(200 + var(--UiToolbar-z-index));

    font-family: 'Texta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    transition: background-color 300ms;

    &.transparent {
        background-color: transparent;
    }

    &,
    button {
        touch-action: manipulation;
    }
}

.UiButton.variant-filled.color-default {
    background-color: rgba(35, 35, 35, 1) !important;
}

html:not([dark]) {
    --color-background-transparent: rgba(255, 255, 255, 0.9);
}

body {
    -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
    -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
    -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    width: 100%;
}

.UiButton.size-2 {
    font-weight: bold;
}

@media (max-width: 1080px) {
    body {
        -webkit-user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }
}

* {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar {
    display: none;
    background-color: transparent;
    width: 0 !important;
}

html,
body,
#root {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    line-height: 1rem;
}

video,
img {
    max-height: 100%;
    max-width: 100%;
}

.animated {
    animation-duration: 300ms !important;
}

body,
button {
    color: var(--color-text-primary);
}

a {
    color: var(--color-text-primary);
    text-decoration: none;
}

.full-width {
    width: 100%;
}

.full-height {
    width: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.hg-button {
    margin-right: 5px;
    border: 1px solid #333;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: center;
    flex: 1;
}
